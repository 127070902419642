import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AnyAction as BaseAction } from 'redux';
import { TenantConfigViewData, TenantConfigViewItem, ViewDefnState } from 'src/dao/tenantConfigClient';
import { ViewDataState } from 'src/types/Domain';
import { AppState, AppThunkDispatch } from 'src/store';
import service from 'src/ServiceContainer';
import Axios from 'src/services/axios';
import { concat, defaultTo, findIndex, get, isEmpty, isNil, isString, reject, set } from 'lodash';
import { ClientDataApi, isListDataApi } from 'src/services/configuration/codecs/confdefnView';
import { getLocalConfig } from '../ViewConfiguratorModal/ViewConfiguratorModal.utils';
import { ConfigurableGridOwnProps } from './ConfigurableGrid.types';
import { BasicItem, BasicPivotItem } from 'src/worker/pivotWorker.types';
import { parseFloorsetDropdownData } from './utils/ConfigurableGrid.utils';
import { ComponentErrorType, ErrorBoundaryComponentError } from 'src/components/ErrorBoundary/ErrorBoundary.slice';
import { ConfDefnComponentType } from 'src/services/configuration/codecs/confdefnComponents';
import { ASSORTMENT } from 'src/utils/Domain/Constants';
import { CoarseEditPayload } from 'src/dao/pivotClient';
import { ConfigurableGridViewDefn } from 'src/services/configuration/codecs/viewdefns/viewdefn';
import { formatViewDefnError } from 'src/services/configuration/codecs/viewdefns/general';
import { updateConfigureSelections } from '../Subheader/Subheader.slice';
import { parseConfigureConfig } from '../Configure/Configure';
import { AppType } from 'src/services/configuration/codecs/bindings.types';
import coalesce from 'src/utils/Functions/Coalesce';
import { getGroupBySelectedOptionProperty } from 'src/utils/Pivot/Sort';
import queryString from 'query-string';

export interface ConfigurableGridGroupBySelection {
  selectedIndex: number;
  option: TenantConfigViewItem;
}

export interface ConfigurableGridSlice {
  viewDefnState: ViewDefnState;
  floorsetDataState: ViewDataState;
  gridDataState: ViewDataState;
  unmodifiedViewDefn: any;
  viewDefn: any;
  gridData: BasicPivotItem[];
  topAttributesData: BasicPivotItem | undefined;
  floorsetData: BasicItem[];
  groupBySelection: ConfigurableGridGroupBySelection | undefined;
  floorsetSelection: string | undefined;
}

const initialState: ConfigurableGridSlice = {
  viewDefnState: ViewDefnState.idle,
  floorsetDataState: ViewDataState.idle,
  gridDataState: ViewDataState.idle,
  unmodifiedViewDefn: undefined,
  viewDefn: undefined,
  gridData: [],
  topAttributesData: undefined,
  floorsetData: [],
  groupBySelection: undefined,
  floorsetSelection: undefined,
};

const configurableGridSlice = createSlice({
  name: 'ConfigurableGrid',
  initialState,
  reducers: {
    requestConfigurableGridConfig(state) {
      state.viewDefnState = ViewDefnState.loading;
    },
    receiveConfigurableGridConfig(state, action: PayloadAction<{ viewDefn: any; unmodifiedViewDefn: any }>) {
      state.viewDefnState = ViewDefnState.loaded;
      state.unmodifiedViewDefn = action.payload.unmodifiedViewDefn;
      state.viewDefn = action.payload.viewDefn;
    },
    updateConfigurableGridConfig(state, action: PayloadAction<any>) {
      state.viewDefn = action.payload;
    },
    requestGridData(state) {
      state.gridDataState = ViewDataState.liveDataLoadingNoCache;
    },
    receiveGridData(
      state,
      action: PayloadAction<{ gridData: BasicPivotItem[]; topAttributesData: BasicPivotItem | undefined }>
    ) {
      state.gridDataState = ViewDataState.liveDataReady;
      state.gridData = action.payload.gridData;
      state.topAttributesData = action.payload.topAttributesData;
    },
    syncGridData(state, action: PayloadAction<{ redecoratedData: BasicPivotItem[]; idProp: string }>) {
      const { redecoratedData, idProp } = action.payload;
      const unsyncedData = state.gridData;
      const syncedData = unsyncedData.map((dataItem) => {
        const syncedDataItem = redecoratedData.find((dd) => dd[idProp] === dataItem[idProp]);
        return isNil(syncedDataItem) ? dataItem : syncedDataItem;
      });

      state.gridData = syncedData;
    },
    requestFloorsetData(state) {
      state.floorsetDataState = ViewDataState.liveDataLoadingNoCache;
    },
    receiveFloorsetData(state, action: PayloadAction<BasicItem[]>) {
      state.floorsetDataState = ViewDataState.liveDataReady;
      state.floorsetData = action.payload;
      if (!isNil(state.floorsetSelection)) {
        // Note that we search for floorset selection based on name, instead of id,
        // due to legacy reasons.  All other floorset selections are based on this,
        // see `ConfigurableGrid.selector` `getFloorsetDropdownProps()` or
        // `ConfigurableGrid.tsx` `handleChangeFloorsetDropdown()`
        const foundSelection = state.floorsetData.find((i) => i.name === state.floorsetSelection);
        if (!foundSelection) state.floorsetSelection = state.floorsetData[0].name;
      }
    },
    setGroupBySelection: (state, action: PayloadAction<ConfigurableGridGroupBySelection>) => {
      state.groupBySelection = action.payload;
    },
    resetGroupBySelection: (state) => {
      state.groupBySelection = undefined;
    },
    setFloorsetSelection: (state, action: PayloadAction<string>) => {
      state.floorsetSelection = action.payload;
    },
    refreshConfigurableGridData: () => {
      // nothing to do here, this action triggers refetch in epic
    },
    receiveError: (_state, _action: PayloadAction<ErrorBoundaryComponentError>) => {
      return initialState;
    },
    cleanUp: () => {
      return initialState;
    },
  },
});

export const {
  requestConfigurableGridConfig,
  receiveConfigurableGridConfig,
  updateConfigurableGridConfig,
  requestGridData,
  receiveGridData,
  syncGridData,
  requestFloorsetData,
  receiveFloorsetData,
  setGroupBySelection,
  resetGroupBySelection,
  setFloorsetSelection,
  refreshConfigurableGridData,
  receiveError,
  cleanUp,
} = configurableGridSlice.actions;

export function fetchConfigurableGridConfigs(configApi: ClientDataApi) {
  return async (dispatch: AppThunkDispatch, getState: () => AppState): Promise<BaseAction | void> => {
    const defnId = get(configApi.params, 'defnId', '_CONFIGGRID_MISSINGDEFNID_');
    const appName = get(configApi.params, 'appName', AppType.Assortment) as AppType;

    try {
      const configResponse = await service.tenantConfigClient.getTenantViewDefnsWithFavorites({
        defnIds: [defnId],
        appName,
        validationSchemas: [ConfigurableGridViewDefn],
      });
      // TODO: type response, then fix slice type as well
      const config: any = configResponse[0];
      const localConfig = getLocalConfig(
        defnId,
        (configResponse as any)[1],
        dispatch,
        (configResponse[0] as unknown) as TenantConfigViewData
      );

      if (localConfig && localConfig.config && (localConfig.config.columns || localConfig.config.view)) {
        const view = (localConfig.config as unknown) as ConfigurableGridViewDefn;
        // This must be done specially, as it doesn't use the subheader groupBy
        if (view.subheaderDropdowns && view.subheaderDropdowns.groupBy) {
          if (!isNil(localConfig.groupBySelection)) {
            dispatch(
              setGroupBySelection({
                selectedIndex: localConfig.groupBySelection,
                option: view.subheaderDropdowns.groupBy.options[localConfig.groupBySelection],
              })
            );
          } else {
            const subHCurrentSelection = getState().subheader.groupBy.selection;
            const gridCurrentSelection = getState().pages.assortmentBuild.configurableGrid.groupBySelection
              ?.selectedIndex;
            const defaultSelection = view.subheaderDropdowns.groupBy.defaultSelection;
            const currentSelection = coalesce(gridCurrentSelection, subHCurrentSelection, defaultSelection) ?? 0;
            dispatch(
              setGroupBySelection({
                selectedIndex: currentSelection,
                option: view.subheaderDropdowns.groupBy.options[currentSelection],
              })
            );
          }
        }
        if (!isNil(view.configure)) {
          if (localConfig.configurationSelections) {
            dispatch(updateConfigureSelections(localConfig.configurationSelections));
          } else {
            const { defaultSelections } = parseConfigureConfig(view.configure as any);
            dispatch(updateConfigureSelections(defaultSelections));
          }
        }
      } else {
        // This code is cloned because we fall back to base viewdefn if nothing present in favorites
        if (config.subheaderDropdowns && config.subheaderDropdowns.groupBy) {
          const groupByConfig = config.subheaderDropdowns.groupBy;
          // Denver, what is going on here??
          // Configurable grid uses it's own subheader options and selection separate from other views,
          // and we haven't merged it in with subheader selections yet, despite it being nearly identical.
          // Because someone wants group bys to be preserved between views,
          // we need to consume three things when trying to figure out the current group by:
          // 1. The current config grid group by
          // 2. The current subheader group by
          // 3. The default selected config grid group by
          // Then, very carefully, fuse those three things togethter to determine the correct current group by

          // first figure out the incoming subheader option, and if it's dataIndex is in configgrid group by
          let subheaderDataIndex: string | undefined = getGroupBySelectedOptionProperty(
            getState().subheader.groupBy,
            'dataIndex'
          );
          const incomingSelectionInCurrentOptions = groupByConfig.options.find(
            (o: { dataIndex: string }) => o.dataIndex === subheaderDataIndex
          );
          if (!incomingSelectionInCurrentOptions) {
            // if the incoming subheader option isn't in the new set of options, dump it
            subheaderDataIndex = undefined;
          }

          // then we try and figure out if there's an incoming config grid group by
          let gridCurrentDataIndex = getState().pages.assortmentBuild.configurableGrid.groupBySelection?.option
            .dataIndex;
          const incomingGridOption = groupByConfig.options.find(
            (o: { dataIndex: string }) => o.dataIndex === gridCurrentDataIndex
          );
          if (!incomingGridOption) {
            // if the incoming config grid option (from another instance of configgrid) isn't in the new set of options, dump it
            gridCurrentDataIndex = undefined;
          }

          // finally get the default
          const defaultDataIndex = get(groupByConfig.options, groupByConfig.defaultSelection)?.dataIndex;

          // fuse them all together
          const coalescedDataIndex =
            coalesce(
              gridCurrentDataIndex,
              subheaderDataIndex === '' ? undefined : subheaderDataIndex, // getGroupBySelectedOptionProperty returns '' if the option isn't found, so fix that here
              defaultDataIndex
            ) ?? '';

          // now that we have the dataIndex, turn it back into an array index
          const coalescedIndex = groupByConfig.options.findIndex(
            (o: { dataIndex: string }) => o.dataIndex === coalescedDataIndex
          );

          dispatch(
            setGroupBySelection({
              selectedIndex: coalescedIndex,
              option: groupByConfig.options[coalescedIndex],
            })
          );
        }
        if (!isNil(config.configure)) {
          const { defaultSelections } = parseConfigureConfig(config.configure as any);
          dispatch(updateConfigureSelections(defaultSelections));
        }
      }
      const configWithFavorites = localConfig && localConfig.config ? localConfig.config : config;

      dispatch(receiveConfigurableGridConfig({ unmodifiedViewDefn: configResponse[0], viewDefn: configWithFavorites }));
    } catch (error) {
      const err = error as any;
      dispatch(
        receiveError({
          type: ComponentErrorType.config,
          message: formatViewDefnError(error),
          name: ConfDefnComponentType.configurableGrid,
          issues: error,
          defnId: err.defnId,
        })
      );
    }
  };
}

export function fetchFloorsetData(floorsetApi: ClientDataApi | undefined) {
  return async (dispatch: AppThunkDispatch, getState: () => AppState): Promise<BaseAction | void> => {
    if (isNil(floorsetApi)) {
      // if there is no magic floorset dropdown, skip the rest of this and let the function go to
      // fetchConfigurableGridData(), where we will attempt to make a listData call without
      // sending in the floorset information
      dispatch(refreshConfigurableGridData());
      return;
    }

    dispatch(requestFloorsetData());

    const { url: floorsetUrl, headers: floorsetHeaders, params: floorsetParams } = floorsetApi;
    const floorsetOptions = {
      headers: floorsetHeaders,
      params: floorsetParams,
    };
    const currentAppName = getState().perspective.selected?.appType;
    if (!currentAppName) throw new Error('AppName not found, this should not happen');
    set(floorsetOptions, 'params.appName', currentAppName);

    try {
      const floorsets = await Axios.get(floorsetUrl, floorsetOptions).then((resp) => {
        if (isNil(resp.data.data) || resp.data.success === false) {
          // this endpoint doesn't return 5xx when it fails, it returns success: false with a 200 response
          // so manually throw here in that case
          throw new Error('Failed to fetch floorsetdata');
        }
        return resp.data.data as BasicItem[];
      });
      dispatch(receiveFloorsetData(floorsets));
    } catch (error) {
      const err = error as any;
      dispatch(
        receiveError({
          type: ComponentErrorType.data,
          message: (error as Error)?.message,
          name: ConfDefnComponentType.configurableGrid,
          stack: (error as Error)?.stack,
          issues: error,
          defnId: err.defnId,
        })
      );
    }
  };
}

export function fetchConfigurableGridData(ownProps: ConfigurableGridOwnProps) {
  return async (dispatch: AppThunkDispatch, getState: () => AppState): Promise<BaseAction | void> => {
    const scope = getState().scope;
    const { floorsetData, floorsetSelection } = getState().pages.assortmentBuild.configurableGrid;
    const { dataApi, topAttributesApi, topMembers = undefined } = ownProps;
    let queryParamTopMember = '';
    const alternateTopMember = getState().worklist.selectedItem;
    try {
      queryParamTopMember = (queryString.parse(window.location.hash.split('?')[1]).topMember as unknown) as string;
    } catch { }

    const finalTopMembers = topMembers || queryParamTopMember || alternateTopMember;

    let floorsetId: string | undefined;
    if (!isEmpty(floorsetData)) {
      const options = parseFloorsetDropdownData(floorsetData);
      const floorsetIndex = isNil(floorsetSelection)
        ? 0
        : findIndex(options, (option) => option.text === floorsetSelection);
      floorsetId = options[floorsetIndex]?.id || '';
    }

    dispatch(requestGridData());

    const aggBy = dataApi.params?.aggBy;

    let formattedDataProm: Promise<BasicPivotItem[]>;

    // ConfigGrid supports toplevel api calls in both /floorset and /listData mode
    // /floorset requires the floorset list be called first and sent in as a topMember
    // while listData works differently
    if (isListDataApi(dataApi)) {
      const listDataTopMembers = reject([finalTopMembers, floorsetId], isNil).join(',');
      formattedDataProm = service.pivotService
        .listData(dataApi.defnId, AppType.Assortment, {
          ...dataApi.params,
          aggBy,
          nestData: false, // we require flat responses for ConfigurableGrid
          topMembers: listDataTopMembers,
        })
        .then((resp) => resp.tree);
    } else {
      const currentAppName = getState().perspective.selected?.appType;
      if (!currentAppName) throw new Error('AppName not found, this should not happen');
      const { url: dataUrl, headers: dataHeaders, params: dataParams = {} } = dataApi;
      const dataOptions = {
        headers: dataHeaders,
        params: {
          ...dataParams,
          aggBy,
          floorsetId,
          topMembers,
          appName: currentAppName,
        },
      };
      formattedDataProm = Axios.get(dataUrl, dataOptions)
        .then(async (resp) => {
          const dataResponse = resp.data;
          return isString(dataResponse) ? await service.pivotService.deserialize(dataResponse) : dataResponse.list;
        })
        .then((formattedData: BasicPivotItem[]) => {
          // If we are dealing with a nested set, first level must be single item, gets second level
          // (/floorset returns [{channel: ..., children: [<row_records>]}])
          if (formattedData && formattedData[0] && formattedData[0].children && formattedData[0].children.length > 0) {
            return formattedData[0].children;
          } else {
            return formattedData;
          }
        });
    }

    // Add in topAttributes call for floorset
    const members = concat(
      [floorsetId],
      defaultTo(topMembers, defaultTo(scope.selections.productMember, [])),
      defaultTo(scope.selections.locationMember, [])
    ).join(',');

    const finalDefnId: string = isListDataApi(topAttributesApi)
      ? topAttributesApi.defnId // prefer this version for config setup, else fallback to handle legacy configuration
      : defaultTo(topAttributesApi?.params?.defnId, '');

    const topAttributesProm: Promise<BasicPivotItem | undefined> = isEmpty(finalDefnId)
      ? Promise.resolve(undefined)
      : service.pivotService
          .listData(finalDefnId, ASSORTMENT, { topMembers: members })
          .then((attributes) => attributes.tree[0]);

    try {
      const [formattedData, topAttributes] = await Promise.all([formattedDataProm, topAttributesProm]);
      // Trim whitespace from the values within formattedData
      formattedData &&
        formattedData.forEach((attr: BasicPivotItem) => {
          Object.keys(attr).map((k) => (isString(attr[k]) ? (attr[k] = attr[k].trim()) : (attr[k] = attr[k])));
        });

      dispatch(receiveGridData({ gridData: formattedData || [], topAttributesData: topAttributes }));
    } catch (error) {
      const err = error as any;
      dispatch(
        receiveError({
          type: ComponentErrorType.data,
          message: (error as Error)?.message,
          name: ConfDefnComponentType.configurableGrid,
          stack: (error as Error)?.stack,
          issues: error,
          defnId: err.defnId,
        })
      );
    }
  };
}

export function submitConfigurableGridPayload(payload: CoarseEditPayload) {
  return service.pivotService.coarseEditSubmitData(payload);
}

export default configurableGridSlice.reducer;
