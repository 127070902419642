import { style } from 'typestyle';
import { rem } from 'csx';
import { companionStyles } from '../../common-ui/components/CompanionListView/CompanionListView.styles';

export const WRAPPER_SUBHEADER_HEIGHT = 94;

const tabsContainer = style({
  display: 'flex',
  justifyContent: 'flex-end',
  position: 'sticky',
  top: 0,
  zIndex: 50, // style edit name/desc fields have a value of 40
  backgroundColor: 'white',
  paddingBottom: rem(0.25),
  $nest: {
    // removes ugly outline on selected tab and menu button
    '& button:focus': {
      outline: 'none',
    },
  },
});

export const worklistViewContainer = (isPrintMode: boolean) =>
  style({
    $debugName: 'worklistViewContainer',
    overflow: isPrintMode ? 'visible' : 'hidden',
    width: '100%',
    $nest: {
      '.data-container': {
        overflow: isPrintMode ? 'visible' : 'hidden',
        $nest: {
          '& .title': {
            fontSize: rem(1.8),
            fontWeight: 300,
            margin: 0,
            whiteSpace: 'nowrap',
            paddingTop: rem(1),
          },
        },
      },
    },
  });

export const worklistCompanionViewContainer = style({
  $debugName: 'worklist-companion-view',
  display: 'flex',
  flexDirection: 'column',
  $nest: {
    [`& .${companionStyles}`]: {
      flexGrow: 1,
    },
  },
});

const styleEditPreview = style({
  display: 'flex',
  marginBottom: 20,
  width: '100%',
});

const WORKLIST_TAB_HEIGHT = 52;

export default {
  tabsContainer,
  worklistViewContainer,
  styleEditPreview,
  WORKLIST_TAB_HEIGHT,
};
