import { connect } from 'react-redux';
import { Lens, fromTraversable, Prism } from 'monocle-ts';
import { Traversable } from 'fp-ts/lib/Array';
import { get } from 'lodash';
import container from 'src/ServiceContainer';
import { AppState, AppThunkDispatch } from 'src/store';
import { WorklistValueProps, WorklistDispatchProps } from 'src/pages/Worklist/Worklist.types';
import { WorklistOwnProps } from 'src/services/configuration/codecs/Worklist';
import {
  requestWorklistConfig,
  receiveWorklistConfig,
  receiveWorklistError,
  updateSelectedItem,
  updateSelectedLevel,
  updateWorklistFlowStatus,
  updateWorklistSearch,
  WorklistSlice,
} from 'src/pages/Worklist/Worklist.slice';
import { ASSORTMENT } from 'src/utils/Domain/Constants';
import { worklistLens, subheaderLens } from 'src/services/lenses/lenses';
import { makePrintSensitive } from 'src/components/higherOrder/Print/PrintSenstive';
import Worklist from 'src/pages/Worklist/Worklist';
import { updateSearch } from 'src/components/Subheader/Subheader.slice';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { update } from 'src/services/lenses/Lenses.actions';
import { getProcessedData } from './Worklist.selectors';
import { FabType, getfabProps, withFab } from 'src/components/higherOrder/withFab';
import { isDataLoaded } from 'src/services/pivotServiceCache';
import { updateAssortmentPlan } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.actions';
import { preSetActiveSubPage, setActiveSubPage } from 'src/pages/NavigationShell/NavigationShell.slice';
import { WorklistViewDefn } from 'src/services/configuration/codecs/viewdefns/viewdefn';
import { ComponentErrorType } from 'src/components/ErrorBoundary/ErrorBoundary.slice';
import { ConfDefnComponentType } from 'src/services/configuration/codecs/confdefnComponents';
import { formatViewDefnError, GroupViewItem } from 'src/services/configuration/codecs/viewdefns/general';
import { AdornmentType } from 'src/services/configuration/codecs/viewdefns/literals';

import { withRouter } from 'src/components/higherOrder/withRouter';

export function mapStateToProps(state: AppState, ownProps: WorklistOwnProps): WorklistValueProps {
  const { title, defns, fabType = FabType.none, showFlowStatus } = ownProps;
  const fabProps = getfabProps(state, fabType);
  const viewState: WorklistSlice = worklistLens.get(state);
  const subheader = subheaderLens.get(state);
  const { filteredStyles, treeStyles } = getProcessedData(state);
  const isLoading = viewState.isConfigLoading || !isDataLoaded(viewState.viewDataState);
  const adornments: AdornmentType[] = get(viewState.viewDefn, 'adornments', []);

  return {
    title,
    config: viewState.viewDefn,
    styles: filteredStyles,
    treeStyles,
    subheaderSlice: subheader,
    subheaderViewDefns: defns.subheader,
    isLoading,
    viewDataState: viewState.viewDataState,
    showLevel: ownProps.showLevel,
    selectedLevel: viewState.selectedLevel,
    flowStatus: viewState.worklistFlowStatus,
    flowStatusConfig: subheader.flowStatusConfig,
    showFlowStatus: showFlowStatus == true ? true : false,
    companionSearch: viewState.worklistSearch,
    activeSubPage: state.perspective.activeSubPage,
    adornments,
    fab: fabProps,
    isPrintMode: state.print.isPrintMode,
  };
}

export function dispatchToProps(dispatch: AppThunkDispatch, ownProps: WorklistOwnProps): WorklistDispatchProps {
  const { defns } = ownProps;
  return {
    async onShowView() {
      // retrieve configs
      dispatch(requestWorklistConfig());

      try {
        const configs = await container.tenantConfigClient.getTenantViewDefns({
          defnIds: defns.view,
          appName: ASSORTMENT,
          // TODO: need to fix the schema to match updated version (include all view types)
          // validationSchemas: [zWorklistViewDefn],
        });

        const [worklistConfig] = configs;
        if (worklistConfig) {
          dispatch(receiveWorklistConfig((worklistConfig as unknown) as WorklistViewDefn));
        }
      } catch (error) {
        dispatch(
          receiveWorklistError({
            type: ComponentErrorType.config,
            message: formatViewDefnError(error),
            name: ConfDefnComponentType.worklist,
            issues: error,
          })
        );
      }
    },
    updateSearchString(value: string) {
      dispatch(updateSearch(value));
    },
    updateCompanionSearchString(value: string) {
      dispatch(updateWorklistSearch(value));
    },
    selectItem(item: string) {
      dispatch(updateSelectedItem(item));
    },
    selectLevel(level: GroupViewItem) {
      dispatch(updateSelectedLevel(level));
    },
    updateStyleItem(styleId: string, propToUpdate: string, propValue: string) {
      const updated = worklistLens
        // TODO: we have to be smarter about this with caching coming in.
        .compose(Lens.fromPath<WorklistSlice>()(['liveData', 'flat']))
        .composeTraversal(fromTraversable(Traversable)())
        .composePrism(Prism.fromPredicate((item) => item.id === styleId))
        .modify((item: BasicPivotItem) => {
          return {
            ...item,
            [propToUpdate]: propValue,
          };
        });

      dispatch(update(updated, 'Edit Styles loaded'));
    },
    updateAssortmentPlan: () => {
      dispatch(updateAssortmentPlan('PlanQueue'));
    },
    setActiveSubPage(subPage: string) {
      dispatch(preSetActiveSubPage());
      dispatch(setActiveSubPage(subPage));
    },
    updateFlowStatus(values: number[]) {
      dispatch(updateWorklistFlowStatus(values));
    },
  };
}

export default connect(mapStateToProps, dispatchToProps)(makePrintSensitive(withRouter(withFab(Worklist))));
