import numbro from 'numbro';
import React, { CSSProperties } from 'react';
import { classes as tsClasses } from 'typestyle';
import StarRatings from 'react-star-ratings';
import ColorSwatch from 'src/common-ui/components/ColorSwatch/ColorSwatch';
import { BasicItem } from 'src/worker/pivotWorker.types';
import { TenantConfigViewItem } from 'src/dao/tenantConfigClient';
import { ColorSwatchItemProps } from 'src/common-ui/components/ColorSwatch/ColorSwatchItem';
import ValidOption from 'src/components/ValidOption/ValidOption';
import _, { isEmpty } from 'lodash';
import { resolvePath } from 'src/cdn';
import { isNaN } from 'lodash/fp';
import { Checkbox } from '@material-ui/core';
import MultiSelect from 'src/pages/AssortmentBuild/LinePlan/MultiSelect/MultiSelect';
import { ExcelNumberFormat, ICellRendererParams } from '@ag-grid-community/core';
import { getSwatchUrl } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.utils';
import { getArrowStyles, ArrowPercent } from 'src/components/ArrowPercentRenderer/ArrowPercentRenderer';

import noImagePath from 'src/common-ui/images/noimage.jpg';
import { StatusIconRenderer } from 'src/components/StatusIconRenderer/StatusIconRenderer';
import { WorklistIcon, WorklistType } from 'src/components/WorklistIcon/WorklistIcon';
import { starGoldColor } from '../Style/Theme';
import { TrendRenderer } from 'src/components/TrendDetails/TrendDetails';
const noImage = resolvePath(noImagePath);

// this replaces the default en-US lowercase number suffixes with uppercase ones
// NOTE: we use en-US for en-GB, and instead .replace the $ with £
// so this will work for both
const uppercaseAbbreviations = {
  billion: 'B',
  million: 'M',
  thousand: 'K',
  trillion: 'T',
};
numbro.culture('en-US', {
  ...numbro.cultures()['en-US'],
  abbreviations: uppercaseAbbreviations,
});

export interface SwatchValues {
  id: string;
  url: string;
  selected: boolean;
}

export interface RendererExcelFormat {
  dataType: string;
  numberFormat: string | ExcelNumberFormat;
  id?: string;
}

export interface RendererExcelFormatObject {
  [s: string]: RendererExcelFormat;
}

export const RendererToExcelFormats: RendererExcelFormatObject = {
  integer: {
    dataType: 'number',
    numberFormat: '#,##0',
  },
  integerNoCommas: {
    dataType: 'number',
    numberFormat: '##',
  },
  thousand: {
    dataType: 'number',
    numberFormat: '#,##0',
  },
  thousandAbbreviated: {
    dataType: 'number',
    numberFormat: '[>999999]#,,"M";#,"K"',
  },
  percent: {
    dataType: 'number',
    numberFormat: '0.00%',
  },
  percentOneDecimal: {
    dataType: 'number',
    numberFormat: '0.0%',
  },
  oneDecimal: {
    dataType: 'number',
    numberFormat: '0.0',
  },
  twoDecimal: {
    dataType: 'number',
    numberFormat: '0.00',
  },
  threeDecimal: {
    dataType: 'number',
    numberFormat: '0.000',
  },
  simplePercent: {
    dataType: 'number',
    numberFormat: '0%', // ?
  },
  arrowSimplePercent: {
    dataType: 'number',
    numberFormat: '0%', // ?
  },
  arrowPercent: {
    dataType: 'number',
    numberFormat: '0.00%', // ?
  },
  arrowPercentOneDecimal: {
    dataType: 'number',
    numberFormat: '0.0%', // ?
  },
  arrowPercentTwoDecimal: {
    dataType: 'number',
    numberFormat: '0.00%', // ?
  },
  usMoney: {
    dataType: 'number',
    numberFormat: '$#,##0.00',
  },
  usMoneyRounded: {
    dataType: 'number',
    numberFormat: '$#,##0',
  },
  usMoneyAbbreviated: {
    dataType: 'number',
    numberFormat: '[>999999]$#,,"M";$#,"K"',
  },
  usMoneyNoCents: {
    dataType: 'number',
    numberFormat: '$#,##0',
  },
  poundNoCents: {
    dataType: 'number',
    numberFormat: '£#,##0',
  },
};

function moneyAbbreviated(value: number | string, currency: string) {
  const numericValue = +value;
  const outputNegative = numericValue < 0;
  const outputAbbreviated = Math.abs(numericValue) >= 1000;
  const abbreviated = numbro(value)
    .format(`($0.00a)`)
    .replace('$', currency);
  let result = abbreviated;

  // raw formatted value (minus abbreviation and negative parentheses)
  const outputValue = outputNegative
    ? abbreviated.substring(2, abbreviated.length - 2)
    : abbreviated.substring(1, abbreviated.length - 1);

  if (outputAbbreviated && outputValue.length > 5) {
    // outputValue will always be 3 digits after rounding to remove decimal
    result = currency + Number(outputValue).toFixed(0) + abbreviated[abbreviated.length - 1];
  }

  return result;
}

function moneyOrDash(value: number, currency: string) {
  if (Math.abs(value) > Number.EPSILON) {
    return numbro(value)
      .format(`$0,0.00`)
      .replace('$', currency);
  }
  return '-';
}

// Some views need to know if a renderer is a percent so it will send the post differently
// Normally we just check if renderer === percent, but now that we have multiple, this is necessary
export const PERCENT_RENDERERS = [
  'percent',
  'percentOneDecimal',
  'simplePercent',
  'arrowPercent',
  'arrowSimplePercent',
  'arrowPercentOneDecimal',
  'arrowPercentTwoDecimal',
];

const colorPattern = /^color_\w+/i;
/** Extracts config classes that represent css colors, so they can be passed into templateRenderer */
export const colorsFromClasses = (classes: string[]) => classes?.filter((str) => colorPattern.test(str)) ?? [];

export const Renderer = {
  getAgGridExcelStyles(): RendererExcelFormat[] {
    const renMap = this.getAllExcelRenderers();
    return Object.keys(renMap).map((key) => {
      return {
        ...renMap[key],
        numberFormat: {
          format: (renMap[key] as any).numberFormat.replace(/"/g, '&quot;'),
        },
      };
    });
  },
  getAllExcelRenderers(): RendererExcelFormatObject {
    const excelMap = Object.assign({}, RendererToExcelFormats);
    Object.keys(excelMap).forEach((key) => (excelMap[key] = { ...excelMap[key], id: key }));
    return excelMap;
  },
  getExcelRenderer(rendererName: string): RendererExcelFormat | undefined {
    return RendererToExcelFormats[rendererName];
  },
  renderValue(item: BasicItem, configItem: TenantConfigViewItem) {
    let renderedValue;
    const formatOrMask = configItem.mask;
    if (configItem.dataIndex === '&') {
      renderedValue = item;
    } else {
      renderedValue = item[configItem.dataIndex];
    }
    // If the value from the pivot returns NaN, show 0 instead
    if (renderedValue == 'NaN') {
      return '0';
    }

    if (configItem.renderer && Renderer[configItem.renderer] && typeof renderedValue !== 'undefined') {
      renderedValue = Renderer[configItem.renderer](renderedValue, formatOrMask);
    }
    return renderedValue;
  },
  template(value: BasicItem, mask: string) {
    _.templateSettings.interpolate = /{([\s\S]+?)}/g;
    const compiled = _.template(mask);
    return compiled(value);
  },
  styledTemplate(value: BasicItem, mask: string, classes: string[]) {
    _.templateSettings.interpolate = /{([\s\S]+?)}/g;
    const compiled = _.template(mask);
    let fontColor;
    const cssClasses = [];
    const matchedColor = colorsFromClasses(classes);

    if (classes?.includes('bold')) {
      cssClasses.push('bold');
    }
    if (classes?.includes('italic')) {
      cssClasses.push('italic');
    }
    if (!isEmpty(matchedColor)) {
      fontColor = matchedColor.map((str) => str.replace('color_', ''))[0];
    }

    const sp = (
      <span className={tsClasses(...cssClasses)} style={{ color: `${fontColor}` ?? 'inherit' }}>
        {compiled(value)}
      </span>
    );
    return sp;
  },
  /**
   * Applies the renderer format to the given value.
   * @example
   * let value: number = 1234567890;
   * let configItem: = {"text": "Sales $","dataIndex": "slsr","renderer": "thousand","formula": "sum(slsr)"};
   * const renderedValue = Renderer.renderJustValue(value, configItem);
   * // renderedValue is '1,234,567,890'
   * @param value - The value to render.
   * @param {TenantConfigViewItem} configItem - The config item containing the renderer information.
   * @returns {any} - The rendered value.
   */
  renderJustValue(value: any, configItem: TenantConfigViewItem) {
    const formatOrMask = configItem.mask;
    if (configItem.renderer && Renderer[configItem.renderer] && typeof value !== 'undefined') {
      value = Renderer[configItem.renderer](value, formatOrMask);
    }
    return value;
  },

  ynBoolean(value: any) {
    if (value === true) {
      return 'Y';
    } else if (value === false) {
      return 'N';
    }
    return '';
  },

  thousandAbbreviated(value: number | string): string {
    const abbreviated = numbro(value).format('0.00a');
    let result = abbreviated;
    const outputAbbreviated = Number(value) >= 1000;
    if (!outputAbbreviated) {
      return Renderer.thousand(value);
    }

    const outputValue = outputAbbreviated ? abbreviated.substring(0, abbreviated.length - 1) : abbreviated;

    if (outputAbbreviated && outputValue.length > 5) {
      // trims decimals if necessary
      const regEx = new RegExp('[.]\\d\\d', 'g');
      result = abbreviated.replace(regEx, '');
    }

    return result;
  },

  thousand(value: number | string): string {
    return numbro(value).format('0,0');
  },

  percent(value: number | string, mask?: string) {
    if (!mask) {
      mask = '-0.00';
    }

    if (isNaN(value as number) || value == null) {
      return '';
    }

    return numbro(value).format(`${mask}%`);
  },

  percentOneDecimal(value: number | string, mask?: string) {
    if (!mask) {
      mask = '-0.0';
    }

    if (isNaN(value as number) || value == null) {
      return '';
    }

    return numbro(value).format(`${mask}%`);
  },

  threeDecimal(value: number | string) {
    return numbro(value).format('0.000');
  },

  twoDecimal(value: number | string) {
    return numbro(value).format('0.00');
  },

  oneDecimal(value: number | string) {
    return numbro(value).format('0.0');
  },

  simplePercent(value: number | string, mask?: string) {
    if (!mask) {
      mask = '-0';
    }

    if (isNaN(value as number) || value == null) {
      return '';
    }

    return numbro(value).format(`${mask}%`);
  },

  star(value: number | string) {
    if (typeof value === 'string') {
      value = parseFloat(value);
      if (value > 5) {
        value = 5;
      }
    }

    return (
      <StarRatings
        rating={value}
        starRatedColor={starGoldColor}
        starDimension="20px"
        starSpacing="5px"
        numberOfStars={5}
      />
    );
  },

  colorSwatch(
    values: ColorSwatchItemProps[],
    onChange?: (id: string, position: number) => void,
    selected?: number,
    className?: string
  ) {
    const swatchVals = values.map((value, index) => {
      return {
        swatchPath: value.swatchPath,
        id: value.id,
        colorId: value.colorId,
        selected: index === selected,
        noImageUrl: noImage,
      };
    });

    const selectedColor = selected ? selected : 0;

    return (
      <ColorSwatch
        noImageUrl={noImage}
        className={className}
        onChangeColor={onChange}
        selected={selectedColor}
        colors={swatchVals}
        getSwatchUrl={getSwatchUrl}
      />
    );
  },

  validOptions(sizes: string[]) {
    let jsxElements: JSX.Element[] = [];

    if (Array.isArray(sizes) && sizes.length > 0) {
      jsxElements = sizes.map((size, index) => {
        return <ValidOption key={index} value={size} editable={false} />;
      });
    }
    return jsxElements;
  },

  // TODO implement components for special cases as needed
  starRank(value: number | string) {
    return Renderer.star(value);
  },

  exclamation(value: number | string) {
    return numbro(value);
  },

  arrowPercent(value: number | string) {
    // TODO react component
    return numbro(value).format('0%');
  },

  arrowSimplePercent(value: number | string) {
    const numberVal = numbro(value).value();
    const neg = numberVal < 0 ? -1 : 1;
    const arrowStyles = getArrowStyles(numberVal, neg);
    let result = numbro(value).format('0%');

    if (numberVal === null) {
      result = '0%';
    }

    return <ArrowPercent {...arrowStyles} result={result} arrowLeft={false} />;
  },

  arrowPercentOneDecimal(value: number | string) {
    const numberVal = numbro(value).value();
    const neg = numberVal < 0 ? -1 : 1;
    const arrowStyles = getArrowStyles(numberVal, neg);
    let result = numbro(value).format('0.0%');

    if (numberVal === null) {
      result = '0.0%';
    }

    return <ArrowPercent {...arrowStyles} result={result} arrowLeft={false} />;
  },
  arrowLeftPercentTwoDecimal(value: number | string) {
    const numberVal = numbro(value).value();
    const neg = numberVal < 0 ? -1 : 1;
    const arrowStyles = getArrowStyles(numberVal, neg);
    let result = numbro(value).format('0.0%');
    if (numberVal === null || value === 'NaN' || isNaN(numberVal)) {
      result = '0.00%';
    }

    return <ArrowPercent {...arrowStyles} result={result} arrowLeft={true} />;
  },
  arrowPercentTwoDecimal(value: number | string) {
    const numberVal = numbro(value).value();
    const neg = numberVal < 0 ? -1 : 1;
    const arrowStyles = getArrowStyles(numberVal, neg);
    let result = numbro(value).format('0.00%');

    if (numberVal === null || value === 'NaN' || isNaN(numberVal)) {
      result = '0.00%';
    }

    return <ArrowPercent {...arrowStyles} result={result} arrowLeft={false} />;
  },

  colorBand(value: number | string) {
    return numbro(value);
  },

  integer(value: number | string) {
    return numbro(value).format('0');
  },

  integerNoCommas(value: number | string) {
    return numbro(value)
      .format('0')
      .replace(',', '');
  },

  gridImage(value: string) {
    const imgStyle: CSSProperties = {
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url(${value})`,
      height: '100%',
      width: '100%',
    };

    return <div style={imgStyle}>&nbsp;</div>;
  },

  checkbox(isChecked: boolean, onChange: any) {
    return <Checkbox color={'default'} checked={isChecked} onChange={onChange} />;
  },

  integerEditable(value: number, onChange: any, className: any) {
    return <input className={className} onChange={onChange} type="number" value={value} />;
  },

  multiSelect(value: any) {
    return <MultiSelect options={value} />;
  },

  // Currency
  // US Dollar

  usMoney(value: number | string | undefined): string {
    return numbro(value).format('$0,0.00');
  },

  usMoneyRounded(value: number | string) {
    return numbro(value).format('($0.00a)');
  },

  usMoneyAbbreviated(value: number | string): string {
    return moneyAbbreviated(value, '$');
  },

  usMoneyNoCents(value: number | string) {
    return numbro(value).format('$0,0');
  },

  usMoneyOrDash(value: number) {
    return moneyOrDash(value, '$');
  },

  // Euro

  euro(value: number | string): string {
    return numbro(value)
      .format('€0,0.00'.replace('€', '$'))
      .replace('$', '€');
  },

  euroRounded(value: number | string) {
    return numbro(value)
      .format('(€0.00a)'.replace('€', '$'))
      .replace('$', '€');
  },

  euroAbbreviated(value: number | string): string {
    return moneyAbbreviated(value, '€');
  },

  euroNoCents(value: number | string) {
    return numbro(value)
      .format('$0,0')
      .replace('$', '€');
  },

  euroOrDash(value: number) {
    return moneyOrDash(value, '€');
  },

  // Pound

  pound(value: number | string): string {
    return numbro(value)
      .format('£0,0.00'.replace('£', '$'))
      .replace('$', '£');
  },

  poundRounded(value: number | string) {
    return numbro(value)
      .format('(£0.00a)'.replace('£', '$'))
      .replace('$', '£');
  },

  poundAbbreviated(value: number | string): string {
    return moneyAbbreviated(value, '£');
  },

  poundNoCents(value: number | string) {
    return numbro(value)
      .format('$0,0')
      .replace('$', '£');
  },

  poundOrDash(value: number) {
    return moneyOrDash(value, '£');
  },

  statusIcon(status: string) {
    return <StatusIconRenderer value={status} />;
  },

  worklist(type: WorklistType) {
    return <WorklistIcon icon={type} />;
  },
  percentTrend: (value: number) => {
    const percentValue = Renderer.simplePercent(value);
    let trendValue = 'neutral';
    if (value > 0) {
      trendValue = 'positive';
    } else if (value < 0) {
      trendValue = 'negative';
    }
    const rendererParams = { value: trendValue } as ICellRendererParams;
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          width: 'inherit',
        }}
      >
        <TrendRenderer {...rendererParams} />
        <span style={{ fontSize: '1.5em' }}>{percentValue}</span>
      </div>
    );
  },
};

export default Renderer;
