import classNames from 'classnames';
import { get, isNil, last, clone, isEmpty, inRange, first, defaultTo } from 'lodash';
import { BindAll } from 'lodash-decorators';
import numeral from 'src/utils/numbro';
import React from 'react';
// @ts-ignore
import keydown from 'react-keydown';
import { connect } from 'react-redux';
import textWidth from 'text-width';
import PivotManager from '../../../pivot/Pivot.client';
import { PivotCell, CELL_TAG_UNEDITABLE, CELL_TAG_DISABLED, CELL_TAG_LOADING } from '../../../pivot/PivotCell';
import ServiceContainer from 'src/ServiceContainer';

import type {
  ColDef,
  ColGroupDef,
  Column,
  ColumnApi,
  GridApi,
  IRowNode,
  ValueFormatterParams,
  ValueGetterParams,
  ValueSetterParams,
  ColumnEvent,
  RowSpanParams,
  CellClassParams,
  MenuItemDef,
  CellPosition,
  ICellRendererParams,
  HeaderClassParams
} from '@ag-grid-community/core';


import '@ag-grid-enterprise/core';
import { AgGridReact } from '@ag-grid-community/react';
import { OptionalFormatterTextCellEditor } from './Editors/OptionalFormatterTextCellEditor';
import './_AgPivot.scss';
import { IAgPivot, SelectionRange } from './IPivot';

import limitedEvaluate from '../../../services/MathJS.service';
import { Field } from '../../../pivot/Field';
import { Group } from '../../../pivot/Group';
import { DEFAULT_PERCENT_FORMAT, TIME, VERSION_TYPE_LIST } from '../../../utils/Domain/Constants';

import { mapDispatchToProps, mapStateToProps } from './AgPivot.container';
import {
  IAgPivotProps,
  IAgPivotState,
  CellRange,
  CellAdornmentType
} from './AgPivot.types';
import { renderFieldDisplay, between, getRowBoundsFromGridApi } from '../../../utils/Component/Pivot';
import ViewportDatasource from './ViewportDataSource';
import {
  handleVirtualColumnsChanged,
  handlePaste,
  handleNavigateToNextCell,
  handleRangeSelectionChange,
  handleGridReady,
  handleBodyScroll,
  handleProcessHeaderForClipboard,
  handleKeyboardNavigateToNextCell
} from './AgPivot.events';
import { GRID_SAVING, GRID_SAVED, GRID_REFRESHING, GRID_ERROR } from 'src/state/scope/Scope.types';
import { GROUP_DEPTH, S5_ROW, S5_ROW_COUNTER, TAG_DEPTH } from 'src/utils/interface/PivotCell.tags';
import { toast } from 'react-toastify';
import jss from 'jss';
import { jssPreset } from '@material-ui/core/styles';
import { LevelInfo } from 'src/pivot/LevelInfo';
import { mathScope } from 'src/services/configuration/MathJS.service';

const MAIN_GRID_ROW_HEIGHT = 22;
const COL_HEADER_ROW_HEIGHT = 34; // make the headers a little taller
const COL_HEADER_TOP_OFFSET = 4.4;
const COL_WIDTH = 130;
const LEVEL_INDENT_SPACING = 26;
const LOADING_TEXT = '...loading';

const POSITIVE_BAND = 0.011; // .011 since value is non-inclusive for inRange, so will accept 0.01 as 0
const NEGATIVE_BAND = -0.01;

interface BasicRowColParams {
  // this is a subset type of a bunch of other ag-grid types
  // it is NOT exhaustive, it's just what is needed for this component
  node: IRowNode | null,
  colDef: ColDef,
  column?: Column,
  data: PivotCell[],
  value?: string
}

const isColDef = (col: ColDef | ColGroupDef): col is ColDef => {
  return 'colId' in col;
};
const isColGroupDef = (col: ColDef | ColGroupDef): col is ColGroupDef => {
  return 'groupId' in col;
};

@BindAll()
export class AgPivot extends React.Component<IAgPivotProps, IAgPivotState>
  implements IAgPivot {
  private numeral!: typeof numeral;
  private TIME_LEVEL_MAP: Record<string, string> | undefined;

  constructor(props: IAgPivotProps) {
    super(props);
    this.numeral = ServiceContainer.localization;

    // this builds a mappings from level id's to sorted css class names
    // the presumes that the levels in state are sorted
    this.TIME_LEVEL_MAP = props.levels ? props.levels.time[0].data
      .map((lvl: LevelInfo) => lvl.id)
      // @ts-ignore
      .reduce((levelMap, currId, idx, lvlIdArray) => {
        if (idx === lvlIdArray.length - 1 || idx === 0) {
          // the bottom level doesn't renders normally,
          // so it doens't need a special class name
          levelMap[currId] = `level-${idx}`;
        } else {
          levelMap[currId] = `col-header-chevron level-${idx}`;
        }
        return levelMap;
      }, {} as Record<string, string>) :
      undefined;

    const pivotManager = new PivotManager({
      config: props.config.clone(),
      axios: ServiceContainer.axios,
      pivotName: props.pivotName
    });

    this.state = {
      ready: false,
      manager: pivotManager,
      originalConfig: props.config,
      isEditing: false,
      loading: false,
      colDefs: undefined
    };
  }

  public gridApi!: GridApi;
  public columnApi!: ColumnApi;

  // used as a no-render state to not refresh the grid when it's already refreshing
  public refreshCellsQueued = false;
  public viewportDataSource: ViewportDatasource | undefined;
  private rowBuffer = 0;
  public rowGroupOffsets: number[] | undefined;
  private colGroupOffsets: number[] | undefined;
  private lastCellFocusedBeforeRefresh: CellPosition | null = null;
  private nonFrameworkComponents = {
    preParseTextCellEditor: OptionalFormatterTextCellEditor
  };
  public selectionRange: SelectionRange = {
    startX: 0,
    startY: 0,
    endX: 0,
    endY: 0
  };
  public ROW_HEADER_HIDDEN_CLASS = 'row-header-hidden-text';
  private bulkUpdateCells = new Map<string, PivotCell>()

  // event function binding
  private handleVirtualColumnsChanged = handleVirtualColumnsChanged.bind(this);
  private handleBodyScroll = handleBodyScroll.bind(this);
  private handlePaste = handlePaste.bind(this);
  private handleRangeSelectionChange = handleRangeSelectionChange.bind(this);
  private handleGridReady = handleGridReady.bind(this);
  private handleNavigateToNextCell = handleNavigateToNextCell.bind(this);
  private handleKeyboardNavigateToNextCell = handleKeyboardNavigateToNextCell.bind(this);
  public eventCache: {
    [key: string]: (evt: ColumnEvent) => void
  } = {};

  public static getDerivedStateFromProps(
    props: IAgPivotProps,
    state: IAgPivotState
  ): IAgPivotState {
    if (!props.config.equals(state.originalConfig)) {
      return {
        ...state,
        originalConfig: props.config,
        manager: new PivotManager({
          config: props.config.clone(),
          axios: ServiceContainer.axios,
          pivotManager: state.manager,
          pivotName: props.pivotName
        })
      };
    }
    return state;
  }

  public static cellCanBeEdited(cell: PivotCell) {
    return !cell.hasLock() &&
      !cell.hasFrozen() &&
      !cell.hasTag(CELL_TAG_DISABLED) &&
      !cell.isCalculating &&
      !cell.hasTag(CELL_TAG_UNEDITABLE) &&
      !cell.hasTag(CELL_TAG_LOADING);
  }

  public static isSelectionRangeMulti(selectionRange: SelectionRange) {
    return selectionRange.startX !== selectionRange.endX ||
      selectionRange.startY !== selectionRange.endY
      ? true
      : false;
  }


  public static getBandedClass(value: number): string {
    if (inRange(value, NEGATIVE_BAND, POSITIVE_BAND)) {
      return 'percent-no-change';
    }
    return value < NEGATIVE_BAND ? 'percent-negative' : 'percent-positive';
  }

  /*** KEYBOARD EVENTS ***/
  @keydown('alt+up', 'alt+down', 'alt+left', 'alt+right', 'w', 'a', 's', 'd')
  private keyDown(params: React.KeyboardEvent): void {
    return this.handleKeyboardNavigateToNextCell(params);
  }
  /*** END KEYBOARD EVENTS ***/

  public getCellFromRowCol(
    rowChange: number,
    colChange: number,
    previousCellDef: CellPosition
  ): CellPosition {
    if (this.gridApi.getEditingCells().length === 1) {
      // what to do when editing
      const colGroupCount = this.state.manager.config.getRowGroupsCount();
      const colNum = this.gridApi.getAllGridColumns().findIndex(c => c == previousCellDef.column) -
        colGroupCount;
      const pivotCell = this.state.manager.getCell(
        previousCellDef.rowIndex,
        colNum
      );

      if (pivotCell && AgPivot.cellCanBeEdited(pivotCell)) {
        const gridCellDef: CellPosition = {
          rowIndex: previousCellDef.rowIndex,
          column: previousCellDef.column,
          rowPinned: null
        };
        this.gridApi.startEditingCell({
          rowIndex: previousCellDef.rowIndex,
          colKey: previousCellDef.column
        });
        return gridCellDef;
      }
    }

    const colChangeLookup = {
      [-1]: this.gridApi.getDisplayedColBefore(previousCellDef.column),
      0: previousCellDef.column,
      1: this.gridApi.getDisplayedColAfter(previousCellDef.column)
    };

    // @ts-ignore
    const nextColumn = colChangeLookup[colChange];

    const nextRow = previousCellDef.rowIndex + rowChange;
    return {
      rowIndex: nextRow,
      column: nextColumn,
      rowPinned: null
    };
  }

  @keydown('shift+1')
  public keyDebug(e: React.KeyboardEvent) {
    e.preventDefault();
    e.stopPropagation();

    this.refreshCells();
  }
  /*** END KEYBOARD EVENTS ***/

  public getVisibleRange(): CellRange {
    // refreshing all cells for now
    // TODO make this only refresh the visible grid
    const maxRow = this.state.manager.getRowCount() - 1;
    const maxCol = this.state.manager.getColCount() - 1;

    return {
      startRow: 0,
      endRow: maxRow,
      startColumn: 0,
      endColumn: maxCol
    };
  }

  public requestReloadVisibleCells() {
    const manager = this.state.manager;

    const range = this.getVisibleRange();
    manager.forAllPresent((cell, r, c) => {
      if (
        between(r, range.startRow, range.endRow) &&
        between(c, range.startColumn, range.endColumn)
      ) {
        cell.needsReload = true;
      } else {
        cell.isLoaded = false;
      }
    });
  }

  public getSelectedRange() {
    let { startX, startY, endX, endY } = this.selectionRange;

    // return the range in the descending order so we don't have to
    // duplicate this logic elsewhere
    let tmpX;
    let tmpY;
    if (startX > endX) {
      tmpX = startX;
      startX = endX;
      endX = tmpX;
    }

    if (startY > endY) {
      tmpY = startY;
      startY = endY;
      endY = tmpY;
    }

    return {
      startX,
      startY,
      endX,
      endY
    };
  }

  public getSelectedCells(): PivotCell[] {
    // TODO check and make sure this always returns valid cells
    const selectedRange = this.getSelectedRange();
    const results: PivotCell[] = [];
    const endRow = Math.min(
      selectedRange.endY,
      this.state.manager.getRowCount() - 1
    );
    for (let r = selectedRange.startY; r <= endRow; r++) {
      const endCol = Math.min(
        selectedRange.endX,
        this.state.manager.getColCount() - 1
      );
      for (let c = selectedRange.startX; c <= endCol; c++) {
        const cell = this.state.manager.getCell(r, c);
        if (cell) {
          results.push(cell);
        }
      }
    }
    return results;
  }

  public setFocusedCell = () => {
    // TODO: is this really needed, the grid doesn't seem to lose focus after edits anymore?
    // this is really a workaround for the grid losing keyboard focus after refreshing from an edit
    if (isNil(this.lastCellFocusedBeforeRefresh)) {
      return;
    } // defensive check
    const lastCellIndex = this.lastCellFocusedBeforeRefresh.rowIndex;

    // jump the selection back to the next row if the rowcount changes
    const lastCellRowIndex = Math.min(
      lastCellIndex,
      this.gridApi.getDisplayedRowCount() - 1
    );
    const displayRow = this.gridApi.getDisplayedRowAtIndex(lastCellRowIndex);

    if (displayRow) {
      this.gridApi.setFocusedCell(
        lastCellRowIndex,
        this.lastCellFocusedBeforeRefresh.column
      );
    }
  };

  public refreshCells() {
    if (this.gridApi && this.viewportDataSource) {
      this.refreshCellsQueued = true;
      this.lastCellFocusedBeforeRefresh = this.gridApi.getFocusedCell();
      const [firstRowIndex, lastRowIndex] = getRowBoundsFromGridApi(this.gridApi);
      this.requestReloadVisibleCells();

      this.viewportDataSource
        .setViewportRange(firstRowIndex, lastRowIndex) // sets the row data
        .then(() => {
          this.refreshCellsQueued = false;
        });
    }
  }

  private getGroupLength = (group: Group) => {
    return group.getVisible().length;
  }

  public componentDidMount() {
    const { onMount } = this.props;

    if (onMount) {
      onMount();
    }
    if (this.props.handleSetPivot) {
      this.props.handleSetPivot(this)
    }

    // warm these private vars up
    this.rowGroupOffsets = this.state.manager.config.getRowGroups().map(this.getGroupLength);
    this.colGroupOffsets = this.state.manager.config.getColGroups().map(this.getGroupLength);

    // build the colDefs
    this.setState({ colDefs: this.pivotConfigToColumnDefs() });

    this.rowBuffer = this.calculateRowBuffer(this.rowGroupOffsets);
  }
  private calculateRowBuffer = (rowGroupOffsets: number[]): number => {
    return rowGroupOffsets.reduce((total, current, idx, offsets) => {
      const previousValue = get(offsets, [idx - 1], 1);
      return total += current * previousValue;
    }, 1)
  }

  public componentWillUnmount() {
    this.bulkUpdateSave();
  }
  private async bulkUpdateSave() {
    // if bulk update only, we need to send all the edits
    // that have been built up
    if (this.props.bulkUpdateOnly) {
      const cells = Array.from(this.bulkUpdateCells.values());
      if (!isEmpty(cells)) {
        cells.forEach((cell) => {
          if (cell.value) {
            this.state.manager.updateCell(cell.row, cell.col, cell.value.toString());
          }
        });
        this.bulkUpdateCells.clear();
      }
    }
  }

  private getRowHeaderWidth(columnNumber: number) {
    const manager = this.state.manager;

    const rowCount = manager.getRowCount();
    let maxLength = 0;
    for (let i = 0; i < rowCount; i++) {
      const headerField = manager.getRowHeaderField(i, columnNumber);
      if (!headerField) {
        continue;
      }

      // TODO: remove settings from AgPivot for this call
      const text = renderFieldDisplay(headerField, this.props.settings!);
      const width = textWidth(text, {
        family: 'Open Sans',
        size: 12
      });

      const spacing = headerField.group.isNested()
        ? headerField.depth * LEVEL_INDENT_SPACING
        : 0;
      maxLength = Math.max(width + spacing, maxLength);
    }

    const calcWidth = maxLength;
    return calcWidth;
  }

  public componentDidUpdate(
    prevProps: IAgPivotProps,
    _prevState: IAgPivotState
  ) {
    if (this.props.forceRefreshGrid && this.gridApi && !this.refreshCellsQueued) {
      // refresh the grid when requested
      this.refreshCells();

      this.gridApi.addEventListener(
        'cellsReturned',
        this.handleGridHasRefreshed
      );
    }

    if (!prevProps.config.equals(this.props.config) && this.viewportDataSource) {
      // reload cells when config changes
      this.gridApi.setGridOption('columnDefs', []); // we have to nuke the colDefs so ag-grid doesn't try and delta them

      if (this.props.bulkUpdateOnly) {
        // used by smart plan, so that all smartplan updates are submitted in one go,
        // instead of incrementally, which would change each subsequent edit
        this.bulkUpdateSave();
      }
      this.viewportDataSource.changeAsyncHandling(false);

      // reset group counts
      this.rowGroupOffsets = this.state.manager.config.getRowGroups().map(this.getGroupLength);
      this.colGroupOffsets = this.state.manager.config.getColGroups().map(this.getGroupLength);

      this.setState({ colDefs: this.pivotConfigToColumnDefs() });

      this.viewportDataSource.updateManager(this.state.manager); // update the serverSide datasource

      // reset rowBuffer, for row virtualization and the floating row text
      this.rowBuffer = this.calculateRowBuffer(this.rowGroupOffsets);

      this.refreshCells(); // finally redraw the cells
      // attach and fire one rowData call after first mount to refresh the css
      this.gridApi.addEventListener(
        'cellsReturned',
        this.redrawRowsAfterModelUpdate
      );
    }
  }

  public redrawRowsAfterModelUpdate = () => {
    this.gridApi.redrawRows();

    this.gridApi.removeEventListener(
      'cellsReturned',
      this.redrawRowsAfterModelUpdate
    );
  };

  public resetFloatingTextAfterCellsReturned = () => {
    // this can randomly return undef, despite what the type says
    const maybeLeft = this.gridApi.getHorizontalPixelRange().left ?? 0;
    // need to imperatively refresh the floating row,
    // as it requries cell data to know where it should be
    this.handleBodyScroll({
      api: this.gridApi,
      columnApi: this.columnApi,
      context: null,
      type: 'bodyScroll',
      direction: 'vertical',
      left: maybeLeft,
      top: this.gridApi.getVerticalPixelRange().top
    });
  }

  public handleGridHasRefreshed = () => {
    this.props.onCompleteRefreshGrid();
    this.refreshCellsQueued = false;

    this.gridApi.removeEventListener(
      'cellsReturned',
      this.handleGridHasRefreshed
    );
  }

  public getRowColFromParams(params: BasicRowColParams): { row: number, col: number, apiCol: number } {
    if (isNil(params.node) || isNil(params.node.rowIndex)) { throw new Error('No row found, this shouldn\'t happen'); }

    const { rowIndex } = params.node;
    const rowGroupCount = this.state.manager.config.getRowGroupsCount();
    const colIndex = this.gridApi.getAllGridColumns().findIndex(c => c == params.column);

    // dumb defensive check against -1 calls due to weird cell issues
    // TODO: fix this dumb check and make getCell safer
    const apiColIndex =
      colIndex - rowGroupCount < 0 ? 0 : colIndex - rowGroupCount;

    // there's a delta here between the remote server's column numbers,
    // and ag-grid's column numbers (which have the row groupings)
    // currently we're applying that delta here
    return {
      row: rowIndex,
      col: colIndex,
      apiCol: apiColIndex
    };
  }

  public isCellEditable(params: BasicRowColParams): boolean {
    const { manager } = this.state;
    const loc = this.getRowColFromParams(params);

    const cell = manager.getCell(loc.row, loc.apiCol);
    if (cell && cell.value !== undefined) {
      return AgPivot.cellCanBeEdited(cell);
    }
    return false;
  }

  private cellValueGetter(params: ValueGetterParams) {
    const { manager } = this.state;
    const rowGroupCount = manager.config.getRowGroupsCount();
    const loc = this.getRowColFromParams(params);

    if (isNil(params.data)) {
      // defensive check against no data
      // sometimes from a bad row count
      // TODO: count better?
      return LOADING_TEXT;
    }

    if (loc.col < rowGroupCount) {
      const maybeDisplayValue = get(params, `data[${loc.col}].displayValue`);
      return maybeDisplayValue || ' ';
    }

    const cell = params.data[loc.col];
    if (isNil(cell)) {
      return LOADING_TEXT;
    }
    if (isNil(cell.value) || cell.value === 'NaN') {
      return LOADING_TEXT;
    }
    return cell.value;
  }

  private getFormatFromCellLoc(params: BasicRowColParams): string | undefined {
    const loc = this.getRowColFromParams(params);

    const rIntersect = this.state.manager.getRowIntersection(loc.row);
    const cIntersect = this.state.manager.getColIntersection(loc.apiCol);

    const maybeRevisionFormatPath = rIntersect?.maybeGetRevisionLocation() || cIntersect?.maybeGetRevisionLocation();

    const cellMetricField = rIntersect?.getMeasureField() || cIntersect?.getMeasureField();
    if (!cellMetricField) { return; } // this has to exist for the grid to work

    const maybeRowFormat = rIntersect?.getFormat();
    const maybeColFormat = cIntersect?.getFormat();

    if (maybeRevisionFormatPath) {
      return cellMetricField.member.format ?
        cellMetricField.member.format[maybeRevisionFormatPath] :
        DEFAULT_PERCENT_FORMAT;
    }

    return maybeColFormat || maybeRowFormat;
  }

  private cellValueFormatter(params: ValueFormatterParams): string {
    const { value } = params;
    if (value === LOADING_TEXT) return value; // short circuit for loading

    const foundFormat = this.getFormatFromCellLoc(params) || '0.0';
    const loc = this.getRowColFromParams(params);
    const formatIncludesCurrency = foundFormat.includes('$') ||
      foundFormat.includes('£') ||
      foundFormat.includes('￡');

    const cell = this.state.manager.getCell(loc.row, loc.apiCol);

    if (foundFormat && cell) {
      const formatFunction = formatIncludesCurrency
        ? 'formatCurrency' :
        'format';

      // TODO: replace this map with config
      if (cell.currencyId) {
        const curr = cell.currencyId;
        if (curr.includes('USD') && formatIncludesCurrency) {
          const ret = this.numeral(value)[formatFunction](foundFormat);
          return value < 0 ? '-$' + ret.slice(2) : '$' + ret.slice(1);
        } else if (curr.includes('EUR') && formatIncludesCurrency) {
          const ret = this.numeral(value)[formatFunction](foundFormat);
          return value < 0 ? '-€' + ret.slice(2) : '€' + ret.slice(1);
        } else if (curr.includes('GBP') && formatIncludesCurrency) {
          const ret = this.numeral(value)[formatFunction](foundFormat);
          return value < 0 ? '-£' + ret.slice(2) : '£' + ret.slice(1);
        }
      }
      return this.numeral(value)[formatFunction](foundFormat);
    }
    return value;
  }

  private getCellAdornment(cell: PivotCell) {
    const { cellAdornments } = this.props;
    // as of now, a cell can only match one ancillary item and not multiples
    const matchedAdornments = cellAdornments.filter(ancillary => {
      const acceptCoreVers = cell.coreVer?.every(version => ancillary.space.coreVersions.includes(version));
      const acceptAdornmentTags = ancillary.space.tags.every((tag) => cell.tags.includes(tag));
      return acceptCoreVers && acceptAdornmentTags;
    });
    const adornment = defaultTo(first(matchedAdornments)?.adornment, '');
    return adornment;
  }

  private renderCellAdornment(adornment: string, cellValue: string): string[] {
    switch (adornment) {
      case CellAdornmentType.dynamicCaret:
        return ['arrow', AgPivot.getBandedClass(Number(cellValue))];
      default:
        return [];
    }
  }

  public isCellPercentMetric(params: BasicRowColParams): boolean {
    const standardFormat = this.getFormatFromCellLoc(params);

    const containsPercent =
      !isNil(standardFormat) && standardFormat.includes('%');

    return containsPercent;
  }

  private getHeaderClass(params: HeaderClassParams): string[] {
    // this inexplicably is called by aggrid before `onGridReady` starts,
    // so we use `params.api` instead of `this.gridApi`
    const colIndex = params.api.getAllGridColumns().findIndex(c => c == params.column);
    if (colIndex === -1) { return [] }

    const colNum = colIndex - this.state.manager.config.getRowGroupsCount();
    const field = this.state.manager.getColHeaderField(0, colNum);
    const classes: string[] = ['col-group'];

    // safety checks
    const isTime = get<Field, string>(field!, 'member.level.dimension.id') === TIME;
    if (isTime) {
      const levelId = get<Field, string>(field!, 'member.level.level');
      if (typeof levelId === 'string' && this.TIME_LEVEL_MAP && levelId in this.TIME_LEVEL_MAP) {
        classes.push(this.TIME_LEVEL_MAP[levelId]);
      }
    } else if (field && this.colGroupOffsets!.length > 1) {
      const lastChild = (colNum + 1) % this.colGroupOffsets![field.depth] === 0;
      classes.push(`col-group-${(lastChild ? 'last-child' : 'first-children')}`);
    }
    return classes;
  }

  private getMainCellClass(params: BasicRowColParams): string {
    const { manager } = this.state;
    const loc = this.getRowColFromParams(params);
    const foundFormat = this.getFormatFromCellLoc(params);
    let commentClass = '';
    let editableClass = '';
    let frozenClass = '';
    let lockedClass = '';
    let advisoryTags: string[] = [];
    let hasHasAdvisory = '';
    let percentClass: string[] = [];

    const currentCell = manager.getCell(loc.row, loc.apiCol);
    if (currentCell) {
      commentClass = currentCell.hasComments() ? 'has-comments' : '';
      editableClass = !currentCell.hasTag(CELL_TAG_DISABLED) ? 'editable-cell' : '';
      editableClass = !isNil(currentCell.value) ? editableClass : '';
      // !!!!! ------ magic here --------
      // the server's current "locked" construct is mapped to the cell "constrained"
      // and "constrained" it mapped to cell "locked"
      frozenClass =
        currentCell.hasFrozen() && !currentCell.hasTag(CELL_TAG_UNEDITABLE) ? 'frozen' : '';
      lockedClass =
        currentCell.hasLock() && !currentCell.hasTag(CELL_TAG_UNEDITABLE)
          ? 'locked'
          : '';
      if (!isEmpty(currentCell.advisoryTags)) {
        // WARNING: these classes come from major side effects created in Settings.slice.ts
        // see that file for reasones
        // TODO: dont do this
        advisoryTags = currentCell.advisoryTags.map((t) => this.props.advisoryClasses[t]);
        hasHasAdvisory = 'has-advisory';
      }

      const cellAdornment = this.getCellAdornment(currentCell);
      if (!isNil(currentCell.value) && !isEmpty(cellAdornment) && currentCell.value !== 'NaN') {
        percentClass = this.renderCellAdornment(cellAdornment, currentCell.value);
      }
    }
    const className = classNames(
      'data-cell',
      commentClass,
      editableClass,
      frozenClass,
      lockedClass,
      advisoryTags,
      hasHasAdvisory,
      percentClass
    );
    return className;
  }

  private getRowGroupCellClass(params: CellClassParams): string {
    const loc = this.getRowColFromParams(params);
    if (isNil(params.data)) {
      // defensive check against no data
      // such as on initial load
      return classNames('data-cell', 'row-header-cell');
    }

    const currentCell = params.data[loc.col] as PivotCell;
    const depthClass = currentCell.tags.find(t => t.startsWith(TAG_DEPTH))!;
    const groupIndex = currentCell.tags.find(t => t.startsWith(GROUP_DEPTH))!;
    const rowClass = currentCell.tags.find(t => t.startsWith(S5_ROW))!;
    const rowCounter = currentCell.tags.find(t => t.startsWith(S5_ROW_COUNTER))!;

    if (currentCell && currentCell.displayValue === '') {
      return classNames(
        depthClass,
        'data-cell',
        'row-header-cell',
        'row-header-empty-cell'
      );
    }
    return classNames('data-cell', depthClass, groupIndex, rowClass, rowCounter, 'row-header-cell');
  }


  public pivotCellSetter(params: ValueSetterParams) {
    // The normal "live" pivot updater
    let { newValue } = params;

    newValue = newValue.trim();

    // MathJS does most of the numerical checking
    // Note that any valid mathjs expression can come through here
    let valueToSave: number | null = null;
    try {
      // try and evaluate their expression
      valueToSave = limitedEvaluate(newValue, mathScope);
    } catch (e) {
      // if we can't parse their expression, toast and revert it
      toast.error('The grid was unable to parse what you entered, so your edit has been reverted', {
        position: toast.POSITION.TOP_LEFT
      });
      ServiceContainer.loggingService.error(
        'Error: unable to parse an expression the user entered',
        JSON.stringify((e as Error).stack));
      return false;
    }
    // Check if the cell is a percentage metric & divide by 100
    if (this.isCellPercentMetric(params) && !isNil(valueToSave)) {
      valueToSave /= 100;
    }
    this.props.onUpdateGridAsyncState(GRID_SAVING);
    if (params.oldValue === valueToSave) {
      // don't save if we're on the old value
      // can probably do a more comprehensive check here, or maybe no check at all
      this.props.onUpdateGridAsyncState(GRID_SAVED);
      return false;
    }
    if (valueToSave == null) { return false; }
    // TODO: maybe check for a valid number here?

    const manager = this.state.manager;
    const loc = this.getRowColFromParams(params);

    manager.updateCell(loc.row, loc.apiCol, valueToSave.toString()).then(() => {
      // trigger grid state update and callback
      this.props.onUpdateGridAsyncState(GRID_REFRESHING);
      if (this.props.onDataChange) {
        this.props.onDataChange(this);
      }
    }).catch(() => {
      ServiceContainer.loggingService.error('Something went wrong trying to save to the grid');
      this.props.onUpdateGridAsyncState(GRID_ERROR);
    });
    // TODO: set the node here so that the number "appears" to update more quickly,
    // when in reality it will be completely replaced when the api call returns
    // use: https://www.ag-grid.com/javascript-grid-viewport/#updating-data
    return true;
  }

  public pivotCellCachedSetter(params: ValueSetterParams) {
    // This version of pivot setter
    let { newValue } = params;

    newValue = newValue.trim();

    // MathJS does most of the numerical checking
    // Note that any valid mathjs expression can come through here
    let valueToSave: number | null = null;
    try {
      // try and evaluate their expression
      valueToSave = limitedEvaluate(newValue, mathScope);

    } catch (e) {
      // if we can't parse their expression, toast and revert it
      toast.error('The grid was unable to parse what you entered, so your edit has been reverted', {
        position: toast.POSITION.TOP_LEFT
      });
      ServiceContainer.loggingService.error(
        'Error: unable to parse an expression the user entered',
        JSON.stringify((e as Error).stack));
      return false;
    }



    this.props.onUpdateGridAsyncState(GRID_SAVING);
    if (params.oldValue === valueToSave) {
      // don't save if we're on the old value
      // can probably do a more comprehensive check here, or maybe no check at all
      this.props.onUpdateGridAsyncState(GRID_SAVED);
      return false;
    }
    if (valueToSave == null) { return false; }
    // TODO: maybe check for a valid number here?

    const manager = this.state.manager;
    const loc = this.getRowColFromParams(params);

    const rIntersect = manager.getRowIntersection(loc.row);
    const cIntersect = manager.getColIntersection(loc.apiCol);

    const metricField = rIntersect.getMeasureField() || cIntersect.getMeasureField();
    // TODO: probably move the "get measure from row/col intersections" to a helper function
    if (!metricField) { throw new Error('Measure field not found during a write, this shouldnt happen'); }

    const pc = new PivotCell({
      row: loc.row,
      col: loc.apiCol,
      // ignored because pivotCell.value type is messed up
      // @ts-ignore
      value: valueToSave,
      metricId: metricField.member.id
    });
    manager.setCell(loc.row, loc.apiCol, pc);
    // save these in a strong Map with row/col as the key so they get properly overwritten
    this.bulkUpdateCells.set(`${loc.row}:${loc.apiCol}`, pc);
    this.viewportDataSource?.changeAsyncHandling(true);
    this.refreshCells();
    return true;
  }

  private rowGroupValueFormatter(params: BasicRowColParams): string {
    if (params.value) {
      return params.value;
    }
    return LOADING_TEXT;
  }

  private rowGroupsToColDefs = (group: Group, groupIdx: number): ColDef => {
    const colWidth = this.getRowHeaderWidth(groupIdx);
    return {
      colId: group.dimension.id,
      headerName: group.dimension.name,
      pinned: 'left',
      rowSpan: (params: RowSpanParams) => {
        let skipCount = 1;
        let maybeDisplayValue;
        const rowGroupCount = this.state.manager.config.getRowGroupsCount();
        const loc = this.getRowColFromParams(params);
        if (loc.col < rowGroupCount) {
          maybeDisplayValue = get(params, `data[${loc.col}].displayValue`) as string;
        }
        if (maybeDisplayValue) {
          const rowGroupOffsets = this.rowGroupOffsets!;
          if (rowGroupOffsets.length > 1 && groupIdx !== rowGroupOffsets.length - 1) {
            skipCount = rowGroupOffsets
              .slice(groupIdx + 1)
              .reduce((prev, next) => prev * next);
          }
        }
        return skipCount;
      },
      lockPinned: true, // cannot be unpinned
      lockPosition: true, // cannot be moved at all
      suppressMovable: true,
      valueGetter: this.cellValueGetter,
      valueFormatter: this.rowGroupValueFormatter,
      headerClass: 'row-group',
      editable: false,
      cellClass: this.getRowGroupCellClass,
      resizable: true,
      suppressSizeToFit: false,
      width: colWidth,
      cellRenderer: (params: ICellRendererParams) => params.value
    };
  }

  public fieldToColumnDef(field: Field, fieldIdx: number): ColDef {
    const isTime = field.member.level.dimension.id === TIME;
    const isRevision = field.member.type && VERSION_TYPE_LIST.findIndex((t) => field.member.type === t) >= 0;
    const colId = !isRevision ? field.member.id : `${field.member.id}/${field.member.type}`;
    const valueSetter = this.props.bulkUpdateOnly ? this.pivotCellCachedSetter : this.pivotCellSetter;

    return {
      valueSetter,
      colId,
      headerName: field.member.description,
      width: COL_WIDTH,
      valueGetter: this.cellValueGetter,
      valueFormatter: this.cellValueFormatter,
      editable: this.isCellEditable,
      cellClass: this.getMainCellClass,
      cellEditor: OptionalFormatterTextCellEditor, // custom editor that supports non-formatted values
      cellEditorParams: {
        useFormatter: false,
        percentChecker: this.isCellPercentMetric
      },
      sortable: isTime,
      headerClass: this.getHeaderClass,
      suppressMovable: isTime, // dont move time
      resizable: true,
      suppressSizeToFit: false,
      useValueFormatterForExport: false
    };
  }

  private fieldToGroupColumnDef(field: Field, _fieldIdx: number): ColGroupDef {
    return {
      groupId: field.member.id,
      headerName: field.member.description,
      marryChildren: true, // don't do this irl
      children: []
    };
  }

  private buildContextMenu(): (string | MenuItemDef)[] {
    return ['copy', 'copyWithHeaders', {
      name: 'XLS Export',
      action: () => this.props.handleDownloadXLS!()
    }];
  }

  public pivotConfigToColumnDefs(): ColGroupDef[] | ColDef[] {
    const { config } = this.state.manager;

    const colGroups = config.getColGroups();
    const colGroupsLength = colGroups.length;
    const rowGroups = config.getRowGroups();

    // put the rowGroups in as the first set of column groups
    let colDefs: ColDef[] = rowGroups.map(this.rowGroupsToColDefs);

    if (colGroupsLength > 1) {
      // build a list of the colDefs.  The bottom needs to be ColDef, the rest need to be ColGroupDef,
      // ColGroupDef has children
      const childColDefs = colGroups.map((colGroup, idx, groupArr) => {
        const mapFunc = idx === groupArr.length - 1 ? this.fieldToColumnDef : this.fieldToGroupColumnDef;
        return colGroup.getVisible().map<ColDef | ColGroupDef>(mapFunc);
      });

      // work the set up from the bottom
      childColDefs.reverse().forEach((childLvl, idx, childLvlArr) => {
        childLvl.forEach((lvl, _lvlIdx, _lvlArr) => {
          if (idx !== childLvlArr.length - 1) {
            childLvlArr[idx + 1].forEach((parentLevel, _pLevelIdx, _pLevelArr) => {
              const childDef = clone(lvl);
              if (isColDef(childDef) && isColGroupDef(parentLevel)) {
                // @ts-ignore
                childDef.colId = `${parentLevel.groupId}|${lvl.colId}`;
              }
              if (isColGroupDef(parentLevel)) {
                parentLevel.children.push(childDef);
              }
            });
          }
        });
      });

      const rootColGroupDefs = last(childColDefs)!;
      colDefs = colDefs.concat(rootColGroupDefs);
      return colDefs;
    }

    // if there's only one dimension level, apply the rowGroupOffset to the colNum
    // and build a set of ColDefs
    return (colDefs = colDefs.concat(
      colGroups[0].getVisible().map((field, fieldIdx) => {
        return this.fieldToColumnDef(field, fieldIdx + colDefs.length);
      })
    ));
  }

  public render() {
    jss.setup(jssPreset());
    return (
      <React.Fragment>
        <div className='ag-theme-mfpgrid' data-qa={"ag-grid-container"}>
          <AgGridReact
            columnDefs={this.state.colDefs}
            rowModelType={'viewport'}
            rowHeight={MAIN_GRID_ROW_HEIGHT}
            headerHeight={COL_HEADER_ROW_HEIGHT}
            singleClickEdit={true}
            enterNavigatesVertically={true}
            enterNavigatesVerticallyAfterEdit={true}
            stopEditingWhenCellsLoseFocus={true}
            enableRangeSelection={true}
            suppressMultiRangeSelection={true}
            // stuff to turn off
            animateRows={false}
            suppressLoadingOverlay={true}
            suppressNoRowsOverlay={true}
            suppressMiddleClickScrolls={false}
            suppressRowHoverHighlight={true}
            suppressExcelExport={true}
            suppressCsvExport={true}
            getContextMenuItems={this.buildContextMenu.bind(this)}
            // injected components
            components={this.nonFrameworkComponents}
            rowBuffer={this.rowBuffer}
            //  event bindings
            onGridReady={this.handleGridReady}
            navigateToNextCell={this.handleNavigateToNextCell}
            processDataFromClipboard={this.handlePaste}
            processHeaderForClipboard={handleProcessHeaderForClipboard}
            onVirtualColumnsChanged={this.handleVirtualColumnsChanged}
            onDisplayedColumnsChanged={this.handleVirtualColumnsChanged}
            onRangeSelectionChanged={this.handleRangeSelectionChange}
            onBodyScroll={this.handleBodyScroll}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AgPivot);
